var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OperatorHistoryActionType } from "src/app/operator/store/actions/operator-history.action";
import { environment } from "src/environments/environment";
/**
 * Начальное состояние истории данных для отображения.
 */
var initialState = {
    /**
     * История состояний данных для отображения.
     */
    history: [],
    /**
     * Индекс текущего состояния данных для отображения.
     */
    currentIndex: -1,
};
/**
 * Обработчик событий, связанных с историей состояний данных для отображения.
 *
 * @param state Состояние истории данных для отображения.
 * @param action Событие, произошедшее в системе.
 */
export function operatorHistoryReducer(state, action) {
    if (state === void 0) { state = initialState; }
    var result = state;
    switch (action.type) {
        case OperatorHistoryActionType.SAVE: {
            var currentIndex = state.currentIndex;
            var history_1 = state.history.slice(0, currentIndex + 1);
            history_1.push(action);
            if (history_1.length > environment.maxOperatorHistorySize) {
                history_1.shift();
            }
            else {
                currentIndex++;
            }
            result = __assign({}, state, { history: history_1,
                currentIndex: currentIndex });
            break;
        }
        case OperatorHistoryActionType.UNDO: {
            var currentIndex = state.currentIndex;
            if (state.currentIndex > 0) {
                currentIndex--;
            }
            result = __assign({}, state, { currentIndex: currentIndex });
            break;
        }
        case OperatorHistoryActionType.REDO: {
            var currentIndex = state.currentIndex;
            if (state.currentIndex < state.history.length - 1) {
                currentIndex++;
            }
            result = __assign({}, state, { currentIndex: currentIndex });
            break;
        }
        case OperatorHistoryActionType.CLEAR: {
            result = __assign({}, state, { history: [], currentIndex: -1 });
            break;
        }
    }
    return result;
}
