import { OperatorHistoryActionType } from "src/app/operator/store/actions/operator-history.action";
import { OperatorHistoryAction } from "src/app/operator/store/actions/operator-history.action";
import { OperatorHistoryState } from "src/app/operator/store/states/operator-history.state";
import { environment } from "src/environments/environment";

/**
 * Начальное состояние истории данных для отображения.
 */
const initialState: OperatorHistoryState = {

    /**
     * История состояний данных для отображения.
     */
    history: [],

    /**
     * Индекс текущего состояния данных для отображения.
     */
    currentIndex: -1,
};

/**
 * Обработчик событий, связанных с историей состояний данных для отображения.
 *
 * @param state Состояние истории данных для отображения.
 * @param action Событие, произошедшее в системе.
 */
export function operatorHistoryReducer(
    state: OperatorHistoryState = initialState,
    action: OperatorHistoryAction
): OperatorHistoryState {

    let result = state;

    switch (action.type) {

        case OperatorHistoryActionType.SAVE: {

            let currentIndex = state.currentIndex;
            const history = state.history.slice(0, currentIndex + 1);
            history.push(action);

            if (history.length > environment.maxOperatorHistorySize) {

                history.shift();
            }
            else {

                currentIndex++;
            }

            result = {
                ...state,
                history,
                currentIndex,
            };
            break;
        }
        case OperatorHistoryActionType.UNDO: {

            let currentIndex = state.currentIndex;
            if (state.currentIndex > 0) {

                currentIndex--;
            }

            result = {
                ...state,
                currentIndex,
            };
            break;
        }
        case OperatorHistoryActionType.REDO: {

            let currentIndex = state.currentIndex;
            if (state.currentIndex < state.history.length - 1) {

                currentIndex++;
            }

            result = {
                ...state,
                currentIndex,
            };
            break;
        }
        case OperatorHistoryActionType.CLEAR: {


            result = {
                ...state,
                history: [],
                currentIndex: -1,
            };
            break;
        }
    }

    return result;
}
