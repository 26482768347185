import { Action } from '@ngrx/store';
import { OperatorDocumentType } from "src/app/common/models";
import { ApiResponsePayloadAction } from 'src/app/root/store';

import { ApiResponse } from 'src/app/common/models';
import { QueuedDocument } from '../../models';

/**
 * Типы событий, связанные с интерфейсом расклейки страниц.
 */
export enum PageSplitterActionType {

    OPEN = '[PageSplitter] Open page splitter',
    INIT = '[PageSplitter] Init page splitter state',
    DROP = '[PageSplitter] Drop page splitter state',

    CUT = '[PageSplitter] Cut current pages set to new document',
    SELECT_DOCUMENT = '[PageSplitter] Select another document',
    SET_TYPE = '[PageSplitter] Set document type',

    CHANGE_ACTIVE_PAGE = '[PageSplitter] Change current active page',
    CHANGE_FREE_PAGE = '[PageSplitter] Change current free page',

    ROTATE_ACTIVE_PAGE = '[PageSplitter] Rotate current active page',
    ROTATE_FREE_PAGE = '[PageSplitter] Rotate current free page',

    TAKE_PAGE = '[PageSplitter] Take page to set',
    TAKE_ALL_PAGES = '[PageSplitter] Take all pages to set',
    REMOVE_PAGE = '[PageSplitter] Remove page from set',
    REMOVE_ALL_PAGES = '[PageSplitter] Remove all pages from set',

    SEND_DOCUMENTS = '[PageSplitter] Send documents',
    SEND_DOCUMENTS_SUCCESS = '[PageSplitter] Send documents success',
    SEND_DOCUMENTS_FAIL = '[PageSplitter] Send documents fail',

    SEND_AND_STOP_DOCUMENTS = '[PageSplitter] Send and stop documents',
    SEND_AND_STOP_DOCUMENTS_SUCCESS = '[PageSplitter] Send and stop documents success',
    SEND_AND_STOP_DOCUMENTS_FAIL = '[PageSplitter] Send and stop documents fail',
}

/**
 * Событие открытия интерфейса расклейки страниц.
 */
export class PageSplitterOpenAction implements Action {
    readonly type = PageSplitterActionType.OPEN;
    constructor() { }
}

/**
 * Событие сброса состояния интерфейса расклейки страниц.
 */
export class PageSplitterDropAction implements Action {
    readonly type = PageSplitterActionType.DROP;
    constructor() { }
}

/**
 * Событие инициализации интерфейса расклейки страниц.
 */
export class PageSplitterInitAction implements Action {
    readonly type = PageSplitterActionType.INIT;
    constructor(
        public readonly payload: QueuedDocument,
        public readonly types: OperatorDocumentType[],
        public readonly forceDropPages: boolean
    ) { }
}

/**
 * Событие переключение на следующую активную страницу.
 */
export class PageSplitterChangeActivePageAction implements Action {
    readonly type = PageSplitterActionType.CHANGE_ACTIVE_PAGE;
    constructor(public readonly payload: number) { }
}

/**
 * Событие переключение на следующую свободную страницу.
 */
export class PageSplitterChangeFreePageAction implements Action {
    readonly type = PageSplitterActionType.CHANGE_FREE_PAGE;
    constructor(public readonly payload: number) { }
}

/**
 * Событие вращения активной страницы.
 */
export class PageSplitterRotateActivePageAction implements Action {
    readonly type = PageSplitterActionType.ROTATE_ACTIVE_PAGE;
    constructor(public readonly payload: number) { }
}

/**
 * Событие вращения свободной страницы.
 */
export class PageSplitterRotateFreePageAction implements Action {
    readonly type = PageSplitterActionType.ROTATE_FREE_PAGE;
    constructor(public readonly payload: number) { }
}

/**
 * Событие завершения текущего набора страниц и создание нового пустого документа - обрезка.
 */
export class PageSplitterCutAction implements Action {
    readonly type = PageSplitterActionType.CUT;
    constructor() { }
}

/**
 * Событие взятия страницы в набор для текущего документа.
 */
export class PageSplitterTakePageAction implements Action {
    readonly type = PageSplitterActionType.TAKE_PAGE;
    constructor() { }
}

/**
 * Событие взятия всех страниц в набор для текущего документа.
 */
export class PageSplitterTakeAllPagesAction implements Action {
    readonly type = PageSplitterActionType.TAKE_ALL_PAGES;
    constructor() { }
}

/**
 * Событие удаления страницы из набора для текущего документа.
 */
export class PageSplitterRemovePageAction implements Action {
    readonly type = PageSplitterActionType.REMOVE_PAGE;
    constructor() { }
}

/**
 * Событие удаления всех страниц из набора для текущего документа.
 */
export class PageSplitterRemoveAllPagesAction implements Action {
    readonly type = PageSplitterActionType.REMOVE_ALL_PAGES;
    constructor() { }
}

/**
 * Событие установки типа для текущего документа.
 */
export class PageSplitterChangeTypeAction implements Action {
    readonly type = PageSplitterActionType.SET_TYPE;
    constructor(public readonly payload: OperatorDocumentType) { }
}

/**
 * Событие выбора другого документа как текущего.
 */
export class PageSplitterSelectDocumentAction implements Action {
    readonly type = PageSplitterActionType.SELECT_DOCUMENT;
    constructor(public readonly payload: number) { }
}

/**
 * Событие начала отправки расклееных документов на сервер.
 */
export class PageSplitterSendDocumentsAction implements Action {
    readonly type = PageSplitterActionType.SEND_DOCUMENTS;
    constructor() { }
}

/**
 * Событие успешной отправки расклееных документов на сервер.
 */
export class PageSplitterSendDocumentSuccessAction implements Action {
    readonly type = PageSplitterActionType.SEND_DOCUMENTS_SUCCESS;
    constructor() { }
}

/**
 * Событие не успешной отправки расклееных документов на сервер.
 */
export class PageSplitterSendDocumentFailAction implements ApiResponsePayloadAction {
    readonly type = PageSplitterActionType.SEND_DOCUMENTS_FAIL;
    constructor(public readonly payload: ApiResponse) { }
}

/**
 * Событие начала отправки расклееных документов на сервер и остановиться.
 */
export class PageSplitterSendAndStopDocumentsAction implements Action {
    readonly type = PageSplitterActionType.SEND_AND_STOP_DOCUMENTS;
    constructor() { }
}

/**
 * Событие успешной отправки расклееных документов на сервер и остановиться.
 */
export class PageSplitterSendAndStopDocumentSuccessAction implements Action {
    readonly type = PageSplitterActionType.SEND_AND_STOP_DOCUMENTS_SUCCESS;
    constructor() { }
}

/**
 * Событие не успешной отправки расклееных документов на сервер и остановиться.
 */
export class PageSplitterSendAndStopDocumentFailAction implements ApiResponsePayloadAction {
    readonly type = PageSplitterActionType.SEND_AND_STOP_DOCUMENTS_FAIL;
    constructor(public readonly payload: ApiResponse) { }
}

/**
 * Тип, объединяющий все события, связанные с интерфейсом расклейки страниц.
 */
export type PageSplitterAction =
    | PageSplitterOpenAction
    | PageSplitterDropAction
    | PageSplitterInitAction
    | PageSplitterCutAction
    | PageSplitterChangeActivePageAction
    | PageSplitterChangeFreePageAction
    | PageSplitterRotateActivePageAction
    | PageSplitterRotateFreePageAction
    | PageSplitterTakePageAction
    | PageSplitterTakeAllPagesAction
    | PageSplitterRemovePageAction
    | PageSplitterRemoveAllPagesAction
    | PageSplitterChangeTypeAction
    | PageSplitterSelectDocumentAction
    | PageSplitterSendDocumentsAction
    | PageSplitterSendDocumentSuccessAction
    | PageSplitterSendDocumentFailAction
    | PageSplitterSendAndStopDocumentsAction
    | PageSplitterSendAndStopDocumentSuccessAction
    | PageSplitterSendAndStopDocumentFailAction
    ;
