import { OperatorDocumentType } from "src/app/common/models/index";
import { PageDTO } from "src/app/common/models/page-dto.model";
import { QueuedDocument } from "../../models";
import { SplitterPage } from "../../models";
import { SplitterDocument } from "../../models";
import { PageSplitterAction } from "../actions";
import { PageSplitterActionType } from "../actions";
import { PageSplitterState } from "./page-splitter.state";

/**
 * Начальное состояние.
 */
const initialState = new PageSplitterState();

/**
 * Обработчик событий, связанных с работой интерфейса расклейки страниц.
 *
 * @param state Состояние интерфейса расклейки страниц.
 * @param action Событие произошедшее в системе.
 */
export function pageSplitterReducer(
    state: PageSplitterState = initialState,
    action: PageSplitterAction
): PageSplitterState {

    let result: PageSplitterState = state;

    switch (action.type) {

        case PageSplitterActionType.DROP: {

            result = {
                ...new PageSplitterState()
            };

            break;
        }
        case PageSplitterActionType.INIT: {

            if (state.queueDocumentId !== (action.payload ? action.payload.id : null)) {

                if (!!action.payload.reviewedOperatorSplitting && !action.forceDropPages) {

                    result = {
                        ...prepareSplitting(action.payload, action.types),
                    };
                }
                else {

                    result = {
                        ...prepareDocument(action.payload),
                    };
                }
            }
            else {

                result = {
                    ...state,
                    sending: false,
                    sent: false,
                    error: null
                };
            }

            break;
        }
        case PageSplitterActionType.CUT: {

            let documents: SplitterDocument[] = updateDocuments(state);

            result = {
                ...state,
                documents: [
                    ...documents,
                    {
                        id: documents.length,
                        pages: [],
                        type: {
                            name: "Не определён",
                            id: "NOT_EXIST",
                            extended: false,
                            foreign: null,
                            visibleOperatorHelpBadges: [],
                        }
                    }
                ],
                activeDocumentIndex: documents.length,
                activeType: {
                    name: "Не определён",
                    id: "NOT_EXIST",
                    extended: false,
                    foreign: null,
                    visibleOperatorHelpBadges: [],
                },
                activePages: [],
                activePageIndex: -1
            };

            break;
        }
        case PageSplitterActionType.CHANGE_ACTIVE_PAGE: {

            result = {
                ...state,
                activePageIndex: action.payload
            };

            break;
        }
        case PageSplitterActionType.CHANGE_FREE_PAGE: {

            result = {
                ...state,
                freePageIndex: action.payload
            };

            break;
        }
        case PageSplitterActionType.ROTATE_ACTIVE_PAGE: {

            let activePages: SplitterPage[] = state.activePages.map((page: SplitterPage, index: number) => {
                let result: SplitterPage = page;
                if (index == state.activePageIndex) {
                    result = {
                        ...page,
                        rotationAngle: action.payload
                    };
                }
                return result;
            });

            result = {
                ...state,
                activePages
            };

            break;
        }
        case PageSplitterActionType.ROTATE_FREE_PAGE: {

            let freePages: SplitterPage[] = state.freePages.map((page: SplitterPage, index: number) => {
                let result: SplitterPage = page;
                if (index == state.freePageIndex) {
                    result = {
                        ...page,
                        rotationAngle: action.payload
                    };
                }
                return result;
            });

            result = {
                ...state,
                freePages
            };

            break;
        }
        case PageSplitterActionType.TAKE_PAGE: {

            result = {
                ...state
            };

            if (state.freePages.length) {

                let freePages: SplitterPage[] = [
                    ...state.freePages.filter((_: SplitterPage, index: number) => index !== state.freePageIndex)
                ];

                let activePages: SplitterPage[] = [
                    ...state.activePages.slice(0, state.activePageIndex + 1),
                    state.freePages[state.freePageIndex],
                    ...state.activePages.slice(state.activePageIndex + 1, state.activePages.length),
                ];
                result = {
                    ...state,
                    freePages,
                    activePages,
                    freePageIndex: Math.min(state.freePageIndex, freePages.length - 1),
                    activePageIndex: state.activePageIndex + 1
                };
            }

            break;
        }
        case PageSplitterActionType.TAKE_ALL_PAGES: {

            let activePages: SplitterPage[] = [
                ...state.activePages.slice(0, state.activePageIndex + 1),
                ...state.freePages,
                ...state.activePages.slice(state.activePageIndex + 1, state.activePages.length),
            ];

            result = {
                ...state,
                freePages: [],
                activePages,
                activePageIndex: activePages.length - 1

            };

            break;
        }
        case PageSplitterActionType.REMOVE_PAGE: {

            result = {
                ...state
            };

            if (state.activePages.length) {

                let freePages: SplitterPage[] = [
                    ...state.freePages,
                    state.activePages[state.activePageIndex]
                ]
                    .sort((a: SplitterPage, b: SplitterPage) => a.number - b.number);

                let activePages: SplitterPage[] = state.activePages
                    .filter((_: SplitterPage, index: number) => index !== state.activePageIndex);

                let activePageIndex: number = state.activePageIndex - 1;
                if (activePages.length && activePageIndex < 0) {

                    activePageIndex = 0;
                }

                let freePageIndex: number = Math.max(freePages.indexOf(state.freePages[state.freePageIndex]), 0);

                result = {
                    ...state,
                    freePages,
                    activePages,
                    activePageIndex,
                    freePageIndex
                };
            }

            break;
        }
        case PageSplitterActionType.REMOVE_ALL_PAGES: {

            let freePages: SplitterPage[] = [
                ...state.freePages,
                ...state.activePages
            ]
                .sort((a: SplitterPage, b: SplitterPage) => a.number - b.number);

            result = {
                ...state,
                freePages,
                activePages: [],
                freePageIndex: 0,
                activePageIndex: -1
            };

            break;
        }
        case PageSplitterActionType.SET_TYPE: {

            result = {
                ...state,
                activeType: action.payload
            };

            break;
        }
        case PageSplitterActionType.SELECT_DOCUMENT: {

            const documents: SplitterDocument[] = updateDocuments(state)
                .filter(doc => doc.pages.length > 0)
                .filter(doc => doc.type.id !== "NOT_EXIST")
                .map((doc, id) => ({...doc, id}));

            const activeDocumentIndex = action.payload >= documents.length ? documents.length - 1 : action.payload;

            let freePages: SplitterPage[] = [...state.freePages];

            if(documents.length !== state.documents.length) {

                freePages = [...state.freePages, ...state.activePages]
                    .sort((a: SplitterPage, b: SplitterPage) => a.number - b.number);
            }

            const freePageIndex: number = Math.max(freePages.indexOf(state.freePages[state.freePageIndex]), 0);

            result = {
                ...state,
                documents,
                activeDocumentIndex,
                activeType: documents[activeDocumentIndex].type,
                activePages: documents[activeDocumentIndex].pages,
                activePageIndex: documents[activeDocumentIndex].pages.length - 1,
                freePages,
                freePageIndex,
            };

            break;
        }
        case PageSplitterActionType.SEND_DOCUMENTS: {

            const documents: SplitterDocument[] = replaceNonstandardIncompleteWithUnknownType(updateDocuments(state));

            result= {
                ...state,
                documents,
                sending: true,
                sent: false,
                error: null
            };

            break;
        }
        case PageSplitterActionType.SEND_DOCUMENTS_SUCCESS: {

            result= {
                ...state,
                sending: false,
                sent: true,
                error: null
            };

            break;
        }
        case PageSplitterActionType.SEND_DOCUMENTS_FAIL: {

            result= {
                ...state,
                sending: false,
                sent: false,
                error: action.payload
            };

            break;
        }

        case PageSplitterActionType.SEND_AND_STOP_DOCUMENTS: {

            const documents: SplitterDocument[] = replaceNonstandardIncompleteWithUnknownType(updateDocuments(state));

            result = {
                ...state,
                documents,
                sending: true,
                sent: false,
                error: null
            };

            break;
        }
        case PageSplitterActionType.SEND_AND_STOP_DOCUMENTS_SUCCESS: {

            result = {
                ...state,
                sending: false,
                sent: true,
                error: null
            };

            break;
        }
        case PageSplitterActionType.SEND_AND_STOP_DOCUMENTS_FAIL: {

            result = {
                ...state,
                sending: false,
                sent: false,
                error: action.payload
            };

            break;
        }
    }
    return result;
}

/**
 * Функция создания состояния расклейщика страниц для документа из очереди.
 *
 * @param document Документ из очереди.
 *
 * @return Состояние расклейщика страниц.
 */
function prepareDocument(document: QueuedDocument): PageSplitterState {

    let result: PageSplitterState = new PageSplitterState();

    if (document) {

        let pages: SplitterPage[] = document.originalData.pages.map((page: PageDTO) => {

            let id: string = document.recognitionResult.pages.filter((p: any) => page.number === p.number)[0].id;
            let splitterPage: SplitterPage = {
                number: page.number,
                rotationAngle: page.rotationAngle,
                url: `/api/v1/documents/${document.id}/pages/${id}`,
                imageFileId: id,
            };

            return splitterPage;
        });

        result = {
            ...result,
            queueDocumentId: document.id,
            freePages: pages,
        };
    }

    return result;
}

/**
 * Функция создания состояния расклейщика страниц для документа из очереди по имеющемуся плану расклейки.
 *
 * Возвращает состояние, в котором все документы уже расклеены по заданному плану.
 *
 * @param document Документ из очереди.
 * @param documentTypes Типы документов, хранящиеся в state приложения.
 *
 * @return Состояние расклейщика страниц.
 */
function prepareSplitting(document: QueuedDocument, documentTypes: OperatorDocumentType[]): PageSplitterState {

    let result: PageSplitterState = new PageSplitterState();

    if (document) {

        const documents = document.reviewedOperatorSplitting.splitPages.documents;

        result = {
            ...result,
            queueDocumentId: document.id,
            documents: documents.map(doc => ({
                id: doc.id,
                pages: doc.pages.map(page => ({ ...page, url: `/api/v1/documents/${document.id}/pages/${page.imageFileId}`})),
                type: documentTypes.find(type => type.id === doc.type),
            })),
            freePages: [],
            activeDocumentIndex: documents[0].id,
            activeType: documentTypes.find(type => type.id === documents[0].type),
            activePages: documents[0].pages
                .map(page => ({ ...page, url: `/api/v1/documents/${document.id}/pages/${page.imageFileId}`})),
            activePageIndex: 0,
            freePageIndex: -1,
        };
    }

    return result;
}

/**
 * Обновление документов до актуального статуса расклейки.
 *
 * @param state Текущее состояние.
 *
 * @return Новый массив документов с актуальными данными.
 */
function updateDocuments(state: PageSplitterState): SplitterDocument[] {

    return [
        ...state.documents.slice(0, state.activeDocumentIndex),
        {
            id: state.activeDocumentIndex,
            pages: state.activePages,
            type: state.activeType,
        },
        ...state.documents.slice(state.activeDocumentIndex + 1, state.documents.length),
    ];
}

/**
 * Заменяет тип документа с NONSTANDARD_INCOMPLETE на UNKNOWN.
 *
 * @param documents Документы.
 */
function replaceNonstandardIncompleteWithUnknownType(documents: SplitterDocument[]): SplitterDocument[] {

    return documents.map(document => {

        if (document.type && document.type.id === "NONSTANDARD_INCOMPLETE") {

            return {
                ...document,
                type: { id: "UNKNOWN", name: null, foreign: null, extended: false, visibleOperatorHelpBadges: [] }
            };
        }
        return document;
    });
}
