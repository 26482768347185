import { createAction } from "@ngrx/store";
import { props } from "@ngrx/store";
import { StateRecord } from "src/app/operator/models/index";

/**
 * Типы событий, связанные с историей состояний данных для отображения.
 */
export enum OperatorHistoryActionType {
    SAVE = "[Operator history] Save state",
    UNDO = "[Operator history] Undo",
    REDO = "[Operator history] Redo",
    CLEAR = "[Operator history] Clear history",
}

/**
 * Событие, требующее сохранить в историю состояние данных для отображения.
 */
const save = createAction(
    OperatorHistoryActionType.SAVE,
    props<StateRecord>(),
);

/**
 * Событие, требующее сместить индекс состояния данных для отображения в предыдущее значение.
 */
const undo = createAction(
    OperatorHistoryActionType.UNDO,
);

/**
 * Событие, требующее сместить индекс состояния данных для отображения в следующее значение.
 */
const redo = createAction(
    OperatorHistoryActionType.REDO,
);

/**
 * Событие, требующее очистить историю состояний данных для отображения.
 */
const clear = createAction(
    OperatorHistoryActionType.CLEAR,
);

/**
 * Тип, объединяющий все события, связанные с историей состояний данных для отображения.
 */
export type OperatorHistoryAction =
    | ReturnType<typeof save>
    | ReturnType<typeof undo>
    | ReturnType<typeof redo>
    | ReturnType<typeof clear>;

/**
 * Все события, связанные с историей состояний данных для отображения.
 */
export const operatorHistoryActions = {
    save: save,
    undo: undo,
    redo: redo,
    clear: clear,
};
