var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { EventEmitter } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { debounceTime, filter } from "rxjs/operators";
import { distinctUntilChanged } from "rxjs/operators";
import { takeUntil } from "rxjs/operators";
/**
 * Компонент диалога создания и редактирования компании.
 */
var CompanyFormComponent = /** @class */ (function () {
    //endregion
    //region С-tor
    /**
     * Конструктор компонента диалога создания компании.
     *
     * @param _formBuilder Построитель форм
     * @param store Состояние приложения.
     */
    function CompanyFormComponent(_formBuilder, store) {
        this._formBuilder = _formBuilder;
        //region Input
        /**
         * Список стран.
         */
        this.countries = [];
        /**
         * Отображать ли заголовок.
         */
        this.showHeader = true;
        /**
         * Иностранная компания?
         */
        this.foreign = false;
        /**
         * Флаг - нужно ли игнорировать изменения в формах?
         */
        this.ignoreChange = false;
        //endregion
        //region Output
        /**
         * Исходящее событие отправки данных компании.
         */
        this.submitCompany = new EventEmitter();
        /**
         * Исходящее событие - данные в форме изменились.
         */
        this.formChanged = new EventEmitter();
        /**
         * Список стран для вывода в выпадашке.
         */
        this.countriesAsOptions = [];
        /**
         * Список стран для вывода в выпадашке.
         */
        this.rusAndKazCountriesAsOptions = [];
        /**
         * Объект глобальной отписки.
         */
        this._subject = new Subject();
        /**
         * Приватное значение загрузки.
         */
        this._loading = false;
        this._store = store;
    }
    Object.defineProperty(CompanyFormComponent.prototype, "loading", {
        /**
         * Состяоние загрузки
         */
        get: function () {
            return this._loading;
        },
        /**
         * Компонент в состоянии загрузки.
         */
        set: function (loading) {
            this._loading = loading;
            if (this.formGroup) {
                if (loading) {
                    this.formGroup.disable();
                }
                else {
                    this.formGroup.enable();
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyFormComponent.prototype, "value", {
        /**
         * Сеттер установки состояния формы.
         *
         * @param company Состояние для установки.
         */
        set: function (company) {
            if (this.ignoreChange) {
                return;
            }
            this.createOrClearForm();
            if (company) {
                this._companyId = company.id;
                var countryAlpha3Code_1 = company.countryAlpha3Code
                    || (this.foreign ? null : (this.recognitionSettingsCountryAlpha3Code || "RUS"));
                var firstFoundCountry = this.countries.filter(function (country) {
                    return country.alpha3Code === countryAlpha3Code_1;
                });
                var countryToSet = firstFoundCountry.length > 0 ?
                    { id: firstFoundCountry[0].alpha3Code, name: firstFoundCountry[0].shortName }
                    : null;
                this.formGroup.patchValue({
                    name: company.name,
                    shortName: company.shortName,
                    inn: company.inn,
                    kpp: company.kpp,
                    taxId: company.taxId,
                    country: countryToSet,
                    address: company.address && company.address.fullAddress,
                });
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyFormComponent.prototype, "kazakhCompany", {
        /**
         * Компания из Казахстана?
         */
        get: function () {
            return this.formGroup.controls.country.value && this.formGroup.controls.country.value.id === "KAZ";
        },
        enumerable: true,
        configurable: true
    });
    //endregion
    //region Hooks
    CompanyFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.formGroup) {
            this.createOrClearForm();
        }
        this.countriesAsOptions = this.countries.map(function (country) {
            return ({ id: country.alpha3Code, name: country.shortName });
        });
        this.rusAndKazCountriesAsOptions = this.countriesAsOptions
            .filter(function (option) { return option.id === "KAZ" || option.id === "RUS"; });
        if (!this.value && !this.formGroup.controls.country.value) {
            var countryToSet = null;
            if (!this.foreign) {
                var countryCodeToSet_1 = this.recognitionSettingsCountryAlpha3Code === "KAZ" && "KAZ" || "RUS";
                countryToSet = this.rusAndKazCountriesAsOptions.find(function (option) { return option.id === countryCodeToSet_1; });
            }
            this.formGroup.controls.country.setValue(countryToSet);
        }
        this.formGroup.valueChanges
            .pipe(debounceTime(200), takeUntil(this._subject), distinctUntilChanged(function (prev, curr) { return JSON.stringify(prev) === JSON.stringify(curr); }), filter(function () { return !_this.ignoreChange; }))
            .subscribe(function () {
            _this.formChanged.emit(_this.componentId);
        });
    };
    CompanyFormComponent.prototype.ngOnDestroy = function () {
        this._subject.next();
    };
    //endregion
    //region Public
    /**
     * Обработчик нажатия клавиши отправки данных.
     */
    CompanyFormComponent.prototype.submitHandler = function () {
        if (this.formGroup.valid) {
            var companyBaseInfo = {
                id: this._companyId,
                name: this.formGroup.controls.name.value,
                shortName: this.formGroup.controls.shortName.value,
            };
            if (this.foreign && !this.kazakhCompany) {
                var taxId = this.formGroup.controls.taxId.value;
                var fullAddress = this.formGroup.controls.address.value;
                if (fullAddress === "") {
                    fullAddress = null;
                }
                this.submitCompany.emit(__assign({}, companyBaseInfo, { foreign: true, taxId: taxId, address: { fullAddress: fullAddress }, countryAlpha3Code: this.formGroup.controls.country.value.id }));
            }
            else {
                this.submitCompany.emit(__assign({}, companyBaseInfo, { inn: this.formGroup.controls.inn.value, kpp: !this.kazakhCompany && this.formGroup.controls.kpp.value || null, countryAlpha3Code: this.formGroup.controls.country.value.id }));
            }
        }
    };
    /**
     * Очистка или создание пустой формы если ее еще нет.
     */
    CompanyFormComponent.prototype.createOrClearForm = function () {
        if (this.formGroup) {
            this.formGroup.patchValue({
                shortName: null,
                name: null,
                inn: null,
                kpp: null,
                taxId: null,
                country: null,
                address: null,
            });
        }
        else {
            this.formGroup = this._formBuilder.group({
                shortName: null,
                name: null,
                inn: null,
                kpp: null,
                taxId: null,
                country: null,
                address: null,
            });
        }
        this.errorData = null;
    };
    /**
     * Полное описание ошибки.
     */
    CompanyFormComponent.prototype.getErrorMessage = function () {
        var result = null;
        if (this.errorData) {
            result = "" + this.errorData.errorMessage;
            if (this.errorData.details) {
                result = this.errorData.details.reduce(function (str, error) { return str + " " + error.errorMessage; }, result);
            }
        }
        return result;
    };
    /**
     * Форматирование вывода результата поиска страны.
     *
     * @param option Страна.
     */
    CompanyFormComponent.prototype.formatCountry = function (option) {
        return option.id + " - " + option.name;
    };
    return CompanyFormComponent;
}());
export { CompanyFormComponent };
